<template>
    <div class="full-page">
        <div class="card bs-5 w-30r">
            <s-form @submit="changePassword" ref="form">
                <div class="text-center text-muted mb-4">
                    <p>CHANGE PASSWORD</p>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="Old Password" name="old_password" v-model="model.old_password"
                                         :disabled="loading" :rules="rules.old_password" type="password"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-input label="New Password" name="new_password" v-model="model.new_password"
                                         :disabled="loading" :rules="rules.new_password" type="password"/>
                    </div>
                </div>
                <div class=" row mt-4">
                    <div class="col">
                        <btn type="submit" text="Save" size="block" :loading="loading" loading-text="Saving.."
                             :disabled="loading"/>
                    </div>
                </div>
            </s-form>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name       : 'ChangePassword',
    components : {},
    data () {
        return {
            loading : false,
            model   : {
                old_password     : '',
                new_password     : '',
                confirm_password : ''
            },
            rules : {
                old_password : {
                    required : true
                },
                new_password : {
                    required : true
                },
                confirm_password : {
                    required : true
                }
            }
        };
    },
    methods : {
        async changePassword () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.ChangePassword, that.model);
            const json = response.data;
            if (json.success === true) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$notify('Changed Successfully', 'Success', {
                type : 'success'
            });
            this.$router.push('/app/');
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type : 'danger'
            });
        }
    }
};
</script>

<style scoped>
.full-page {
    height: 80vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>
